// @ts-nocheck
/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { ContentPageLayout } from "@components/Layout";
import { Heading, Text, Box, LinkButton } from "@components/Primitives";
import {
  FormattedDate as Date,
  FormattedMessage as M,
  useIntl,
} from "gatsby-plugin-intl";
import { useStores } from "@stores";
import { observer } from "mobx-react";
import { PATHS } from "../../constants/nav-items";
import { get } from "store";
import { getTranslation } from "../../utils/intlUtils";

interface INewsArticlePage {
  newsStore: INewsStore;
  location: any;
}

const Header = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const AllNewsLink = styled(LinkButton)`
  max-width: 250px;
  margin: 4rem auto 0;
`;

const NewsArticlePage: FC<INewsArticlePage> = observer(({ location }) => {
  const { locale } = useIntl();
  const [article, setArticle] = useState<INewsItem>();
  const {
    newsStore: { getArticle },
  }: { newsStore: INewsStore } = useStores();
  const articleId = location.search.split("id=")[1];

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const res = await getArticle(articleId);

        setArticle(res);
      } catch (e) {
        throw e;
      }
    };

    if (articleId) {
      fetchArticle();
    }
  }, [articleId]);

  return (
    <ContentPageLayout>
      {article && (
        <div>
          <Header mb="3">
            <Heading center mb="1" level="1">
              {getTranslation(locale, article, "title")}
            </Heading>
            <Text fontWeight="500">
              <Date value={article.createdAt} />
            </Text>
          </Header>
          <div
            dangerouslySetInnerHTML={{
              __html: getTranslation(locale, article, "content"),
            }}
          />
          <AllNewsLink to={PATHS.news}>
            <M id="pages.news.allNews" />
          </AllNewsLink>
        </div>
      )}
    </ContentPageLayout>
  );
});

export default NewsArticlePage;
